<div fxFlex fxLayout="row">
  <mat-form-field fxFlex>
    <mat-label> {{sfind('titleUppercase', extra) ? (label | translate | uppercase) : (label | translate) }} </mat-label>
    <mat-select [formControl]="control" [value]="value">
      <mat-option [value]="null"> -- {{ "Selector.boolean.all" | translate | capitalize }} -- </mat-option>
      <mat-option [value]="true"> {{"Selector.boolean.yes" | translate | capitalize }}</mat-option>
      <mat-option [value]="false"> {{"Selector.boolean.no" | translate | capitalize }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
