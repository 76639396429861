import { Injectable } from '@angular/core';
import { Logger } from './logger.service';

import { Module, Role } from '@app/@shared/models/user/dtos/user';

import { AWSAuthService } from './aws/auth.service';

const log = new Logger('PermissionService');


interface AccessPermissions {
  email: string;
  user_id: number;
  role: Role;
  policy: { modules: Array<Module> }
}

@Injectable()
export class PermissionService {

  constructor(private awsAuthService: AWSAuthService) { }

  async getRolePermissions(): Promise<AccessPermissions> {
    // TODO: Revise recover permissions from
    // currentAuthenticatedUser >  cognitoUser.getSession()

    const session = await this.awsAuthService.currentSession();
    const token = session.getIdToken().decodePayload();

    const access = {
      email: token.email,
      user_id: token.user_id,
      user_type: token.user_type,
      company_id: token.company_id,
      company_name: token.company_name,
      cognito_groups: token['cognito:groups'] ? token['cognito:groups'] : [],
      ...JSON.parse(token.access.replace(/'/g, '"'))
    }

    // Include user info into currentUser object
    this.awsAuthService.currentUser.user_id = access.user_id;
    this.awsAuthService.currentUser.type = access.user_type;
    this.awsAuthService.currentUser.company_id = access.company_id;
    this.awsAuthService.currentUser.company_name = access.company_name;
    this.awsAuthService.currentUser.cognito_groups = access.cognito_groups;

    return access as AccessPermissions;
  }

  async checkPermissionRoute(routePath: string): Promise<boolean> {

    return true;

    const _access: AccessPermissions = await this.getRolePermissions();

    // Check permission modules in user > role
    const _role_module: Module = _access.role.modules.find(m => routePath.includes(m.path));
    // Check permission modules in user directly
    const _policy_module: Module = _access.policy.modules.find(m => routePath.includes(m.path));

    if (_role_module || _policy_module)
      return true;

    
  }
}
