import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidenav-secundary',
  templateUrl: './sidenav-secundary.component.html',
  styleUrls: ['./sidenav-secundary.component.scss']
})
export class SidenavSecundaryComponent {
  @Output() closeSidenavEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  closeSidenav() {
    this.closeSidenavEvent.emit();
  }
}
