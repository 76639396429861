<div fxLayout="column" fxFlexFill  [ngStyle]="{backgroundImage:'url('+ image +')'}" class="auth-login-container">
    <div fxLayoutAlign="center" style="margin-top:7rem" >
      <img
        class="logo"
        fxLayoutAlign="center center"
        src="assets/icons/logo login.svg"
        style="width: 110px"
      />
    </div>
    <div  fxLayout="row" fxLayoutAlign="center" style="margin:2rem">
      <mat-card fxFlex="30%" >
        <form [formGroup]="loginForm">
          <div fxFlex  fxLayout="column" fxLayoutGap="1.0rem">
            <div fxLayout="column" fxLayoutGap="0.5rem">
              <!-- LOGIN FORM -->
              <mat-form-field>
                <mat-label>{{ 'auth.login.email username' | translate | capitalize }}</mat-label>
                <input type="text" matInput formControlName="username" required />
                <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                  <span>{{ 'Este campo es obligatorio' | translate }}</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'auth.login.password' | translate | titlecase }}</mat-label>
                <input type="password" matInput formControlName="password" autocomplete="off" placeholder  [type]="hidePassword ? 'password' : 'text'"/>
                <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                  <span>{{ 'Este campo es obligatorio' | translate }}</span>
                </mat-error>
                <div fxLayout="row" matSuffix>
                  <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </div>
              </mat-form-field>
             
              <!-- <mat-slide-toggle color="primary" formControlName="remember">
                <span>{{ "auth.login.remember" | translate | capitalize }}</span>
              </mat-slide-toggle> -->
              <br />
              
              <button mat-raised-button type="submit" color="inklusiobat-button" (click)="login()" >
                <span>{{ 'app.actions.enter' | translate | capitalize }}</span>
              </button>

            </div>
          </div>
        </form>
      </mat-card>
    </div>
</div>
