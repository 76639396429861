// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  serviceWorker: true,
  serverUrl: 'https://api.pre.appinkbat.gureak.com/api',
  baseUrl: 'https://appinkbat.gureak.com',
  version: env.npm_package_version + '-preproduction',
  aws: {
    auth: {
      identityPoolId: 'eu-west-1:4ca30f47-1800-404f-935c-85c4a05ace6d',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_hLtHm8cSW',
      userPoolWebClientId: '50b01iauijqvniqol49g155115',
      mandatorySignIn: true,
      oauth: {
        domain: "gureak-application-preproduction.auth.eu-west-1.amazoncognito.com",
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: "https://appinkbat.gureak.com",
        redirectSignOut: "https://appinkbat.gureak.com",
        responseType: 'token',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
    },
  },
  cache: {
    Key: 'http_cache',
    Persistence: 'local', /* local / session / memory */
    Duration: 60 * 24 /* minutes */
  },
  webhook: {
    Endpoint: '',
    Key: '',
    Token: ''
  }
};
