<div fxFlex fxLayout="row">
  <mat-form-field fxFlex>
    <mat-label *ngIf="title"> {{sfind('titleUppercase', extra) ? ("Selector."+title | translate | uppercase) : ("Selector."+title | translate) }} </mat-label>
    <mat-label *ngIf="!title"> {{sfind('titleUppercase', extra) ? ("Selector."+entity | translate | uppercase) : ("Selector."+entity | translate) }} </mat-label>
    <mat-select [formControl]="control" [value]="currentEntity?.id" multiple="false" [disabled]="isDisabled" [required]="isRequired">
      
      <mat-select-trigger>
        {{ select() }}
     </mat-select-trigger>

      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterControl"
          [placeholderLabel]="'Selector.component.search' | translate"
          [noEntriesFoundLabel]="'Selector.component.no-records' | translate"
          [searching]="isLoading"
          [clearSearchInput]="false"
          [disableScrollToActiveOnOptionsChanged]="true"
        >
        </ngx-mat-select-search>
      </mat-option>


      <mat-option *ngIf="allowSetNull" [value]="null"> -- {{ "Selector.component.none" | translate | uppercase }} -- </mat-option>

      <mat-option *ngFor="let entity of EntityList" [value]="entity['id']" fxLayoutAlign="center start">
        <div fxLayout="column">
          <div>
            <span *ngFor="let fl of field_left">
              {{'['+items(entity, fl)+']'}}
            </span>
            <span>
            {{  items(entity, field_entity) }}
            </span>
          </div>
          <div class="field_down" fxLayout="row" fxLayoutAlign="space-between center" >
            <span *ngFor="let fd of field_down">
              {{ items(entity, fd) }}
            </span>
          </div>
        </div>
      </mat-option>
      <button *ngIf="total > EntityList.length" [disabled]="isLoading" (click)="onSeeMoreButton()" mat-button fxFill>
        <mat-icon> expand_more </mat-icon>

        <span
          >{{
            "Selector.component.seeMore"
              | translate
                : { n: total - EntityList.length < param.pagination.pageSize ? total - EntityList.length : param.pagination.pageSize }
          }}
        </span>
      </button>
    </mat-select>
    <button
      [disabled]="isDisabled"
      [matTooltip]="'Selector.component.refresh' | translate"
      (click)="onRefreshButtonClicked($event)"
      mat-icon-button
      matSuffix
      color="primary"
    >
    <mat-icon *ngIf="!isLoading">refresh</mat-icon>
    <mat-spinner *ngIf="isLoading" [diameter]="22" [strokeWidth]="2" matSuffix></mat-spinner>
    </button>
  </mat-form-field>
  </div>