import { Base } from '../../_base';

export interface User extends Base {
  name?: string;
  surname?: string;
  username?: string;
  email?: string;
  phone?: string;
  type?: UserType;
  is_deleted?: boolean;
  manager_id?: number;
  manager?: User;
}

export enum UserFields {
    name = 'name',
    surname = 'surname',
    username = 'username',
    email = 'email',
    phone = 'phone',
    type = 'type',
    is_deleted = 'is_deleted',
    manager_id = 'manager_id'
}


export enum UserType {
  employee = 'employee',
  manager = 'manager',
  administrator = 'administrator'
}

export const UserTypeList = Object.values(UserType)

// PERMISSIONS =================================================================

export interface Role extends Base {
  name: string;
  description?: string;

  modules?: Array<Module>;
}

export interface Module extends Base {
  name?: string;
  code: string;
  path: string;
  description?: string;

  permissions: Array<String>;
}

export interface Permission extends Base {
  code: string;
  description: string;
}
