<div fxFlex class="shell-container">
  <mat-sidenav-container fxFlex fxFill>
    <!-- SIDENAV PRINCIPAL > Left -->
    <mat-sidenav
      id="sidenavPrimary"
      #sidenavPrimary
      [mode]="'side'"
      [fixedInViewport]="true"
      [opened]="isExpanded"
      [disableClose]="false"
      [ngClass]="isExpanded ? 'open' : 'close'"
      fxFlex
    >
      <app-sidenav-primary [isExpanded]="isExpanded" (toggleSidenavPrimaryEvent)="toggleSidenavPrimary()"></app-sidenav-primary>
    </mat-sidenav>

    <!-- SIDENAV SECUNDARY > Right -->
    <mat-sidenav
      #sidenavSecundary
      mode="over"
      opened="false"
      disableClose="false"
      position="end"
      ngClass.gt-sm="mat-sidenav-border"
    >
      <app-sidenav-secundary (closeSidenavEvent)="toggleSidenavSecundary()"></app-sidenav-secundary>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="isExpanded ? '' : 'close'" fxFlex>
      <mat-sidenav-container fxFlex>
        <mat-sidenav-content fxFlex>
          <app-header
            [isMobile]="isMobile"
            (toggleSidenavPrimaryEvent)="toggleSidenavPrimary()"
            (toggleSidenavSecundaryEvent)="toggleSidenavSecundary()"
            (profileChangePasswordEvent)="profileChangePassword()"
          >
          </app-header>
          <div class="mat-container">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
