import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [

  Shell.childRoutes([
    { 
      path: '', 
      redirectTo: '/dashboard', 
      pathMatch: 'full' 
    },
    { 
      path: 'dashboard', 
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) 
    },
    { 
      path: 'user', 
      loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    { 
      path: 'answer', 
      loadChildren: () => import('./answer/answer.module').then(m => m.AnswerModule) 
    },
    
    { 
      path: 'question', 
      loadChildren: () => import('./question/question.module').then(m => m.QuestionModule) 
    },
    { 
      path: 'template', 
      loadChildren: () => import('./template/template.module').then(m => m.TemplateModule) 
    },
    { 
      path: 'survey', 
      loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule) 
    },
    { 
      path: 'panic', 
      loadChildren: () => import('./panic/panic.module').then(m => m.PanicModule) 
    },
    { 
      path: '**', 
      redirectTo: '/dashboard', 
      pathMatch: 'full' 
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
