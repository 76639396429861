export interface ModuleInterface {
  label: string;
  icon?: string;
  alt_icon?: string;
  path?: string;
  sidenav?: boolean;
  expanded?: boolean;
  permissions?: string[];
  submodules?: ModuleInterface[];
}

export const permissionsSettings: ModuleInterface[] = [
  {
    label: 'dashboard.title',
    icon: 'assets/icons/home-1.svg',
    alt_icon: 'assets/icons/home.svg',
    path: '/dashboard',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'user.title',
    icon: 'assets/icons/usuarios-1.svg',
    alt_icon: 'assets/icons/usuarios.svg',
    path: '/user',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'answer.title',
    icon: 'assets/icons/respuestas-1.svg',
    alt_icon: 'assets/icons/respuestas.svg',
    path: '/answer',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'question.title',
    icon: 'assets/icons/preguntas-1.svg',
    alt_icon: 'assets/icons/preguntas.svg',
    path: '/question',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'template.title',
    icon: 'assets/icons/formularios-1.svg',
    alt_icon: 'assets/icons/formularios.svg',
    path: '/template',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'survey.title',
    icon: 'assets/icons/formularios-1.svg',
    alt_icon: 'assets/icons/formularios.svg',
    path: '/survey',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'panic.title',
    icon: 'assets/icons/panico-1.svg',
    alt_icon: 'assets/icons/panico.svg',
    path: '/panic',
    sidenav: true,
    permissions: [],
  }
]


export const passwordSetting: string = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=[^<>{}"/|;:.,~!?@#$%^=&*]*[<>{}"/|;:.,~!?@#$%^=&*]).{8,}'
