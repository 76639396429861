<mat-toolbar class="header-container">
  <!-- <button class="menu-button" mat-icon-button (click)="toggleSidenavPrimaryEvent.emit()" fxHide.gt-sm>
    <mat-icon>menu</mat-icon>
  </button> -->

  <h1><strong>{{ title | uppercase }}</strong></h1>
  <div fxFlex></div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <!-- LANGUAGE -->
    <!-- <app-language-selector icon="true"></app-language-selector> -->

    <!-- PROFILE -->
    <div class="header-profile-container">
      <button mat-flat-button [matMenuTriggerFor]="userMenu">
        <div fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="header-user-profile">
            <span
            ><strong>{{ (currentUser | async)?.username[0] | uppercase }}</strong></span
            >
          </div>
          <span *ngIf="!isMobile">{{ (currentUser | async)?.username | capitalize }}</span>
        </div>
      </button>
    </div>


    <!-- RIGHT SIDENAV (Testing) -->
    <!-- <button mat-icon-button (click)="this.toggleSidenavSecundaryEvent.emit()">
      <mat-icon>align_horizontal_right</mat-icon>
    </button> -->

    <!-- PROFILE > Menu -->
    <div class="header-profile-menu-container" >
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item class="class">
          <mat-icon mat-list-icon>mail</mat-icon>
          <span>{{ (currentUser | async)?.attributes?.email }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="profileChangePasswordEvent.emit()" class="class"> 
          <mat-icon mat-list-icon>lock</mat-icon>
          <span transate>Change Password</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon mat-list-icon>logout</mat-icon>
          <span transate>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>

</mat-toolbar>
