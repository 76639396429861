import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { PasswordChangeComponent } from '@app/auth/password-change-dialog/password-change.component';

import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@app/@shared/services/dialog.service';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit{
  public isExpanded: boolean = true;


  @Output() valueResponse: EventEmitter<boolean> = new EventEmitter();


  @ViewChild('sidenavPrimary') sidenavPrimary: { toggle: () => void; };
  @ViewChild('sidenavSecundary') sidenavSecundary: { toggle: () => void; };
  constructor(
    private media: MediaObserver,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {

  }
  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  toggleSidenavPrimary() {
    this.sidenavPrimary.toggle();
    this.isExpanded = !this.isExpanded;
  }

  toggleSidenavSecundary() {
    this.sidenavSecundary.toggle();
  }

  profileChangePassword() {
    this.dialog.open(PasswordChangeComponent, {
      panelClass: 'mat-dialog-responsive',
      data: {},
    });
  }
}
