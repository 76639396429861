import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { NgModule } from "@angular/core";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";


const routes: Routes = [
    { path: '', component: DashboardComponent, data: { title: marker('dashboard.title') } }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
  })
  export class DashboardRoutingModule { }