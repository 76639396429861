<div fxLayout="column" fxLayoutGap="0.5erm" class="mat-typography">
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{ title | translate | uppercase }}</span>
    <button mat-icon-button mat-dialog-close [style.top.px]=-20 [style.right.px]=-20 *ngIf="closeButton">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <span>{{ message | translate }}</span>
  </mat-dialog-content>
  <br />
  <mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
    <button fxFlex mat-raised-button color="inklusiobat-dialog" #cancelBtn (keydown.arrowright)="acceptBtn.focus()" [matDialogClose]="false">
      {{ cancelButton | translate }}
    </button>
    <button fxFlex mat-raised-button color="inklusiobat-dark" #acceptBtn (keydown.arrowleft)="cancelBtn.focus()" [matDialogClose]="true" cdkFocusInitial>
      {{ acceptButton | translate }}
    </button>
  </mat-dialog-actions>
</div>
