<div class="password-hints" fxLayout="column">
  <mat-hint class="password-hints-title">
    <span><strong>{{ 'user.form.password.password requirements' | translate }}</strong></span>
  </mat-hint>
  <mat-hint>
    <mat-icon class="material-icons-outlined check-icon" *ngIf="!formControl.errors?.formatLength && formControl.value">done</mat-icon>
    <mat-icon class="material-icons pending-icon" *ngIf="formControl.errors?.formatLength || !formControl.value">close</mat-icon>
    <span>{{ 'user.form.password.password requirements 1' | translate }}</span>
  </mat-hint>
  <mat-hint>
    <mat-icon class="material-icons-outlined check-icon" *ngIf="!formControl.errors?.formatUppercase && formControl.value">done</mat-icon>
    <mat-icon class="material-icons pending-icon" *ngIf="formControl.errors?.formatUppercase || !formControl.value">close</mat-icon>
    <span>{{ 'user.form.password.password requirements 2' | translate }}</span>
  </mat-hint>
  <mat-hint>
    <mat-icon class="material-icons-outlined check-icon" *ngIf="!formControl.errors?.formatLowercase && formControl.value">done</mat-icon>
    <mat-icon class="material-icons pending-icon" *ngIf="formControl.errors?.formatLowercase || !formControl.value">close</mat-icon>
    <span>{{ 'user.form.password.password requirements 3' | translate }}</span>
  </mat-hint>
  <mat-hint>
    <mat-icon class="material-icons-outlined check-icon" *ngIf="!formControl.errors?.formatNumber && formControl.value">done</mat-icon>
    <mat-icon class="material-icons pending-icon" *ngIf="formControl.errors?.formatNumber || !formControl.value">close</mat-icon>
    <span>{{ 'user.form.password.password requirements 4' | translate }}</span>
  </mat-hint>
  <mat-hint>
    <mat-icon class="material-icons-outlined check-icon" *ngIf="!formControl.errors?.formatSpecialCharacter && formControl.value">done</mat-icon>
    <mat-icon class="material-icons pending-icon" *ngIf="formControl.errors?.formatSpecialCharacter || !formControl.value">close</mat-icon>
    <span>{{ 'user.form.password.password requirements 5' | translate }}</span>
  </mat-hint>
</div>
