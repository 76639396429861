<div>
  <mat-card class="auth-login-container">
    <mat-card-content >
      <mat-card-title class="card-title" >
          <img
            class="logo"
            src="assets/icons/logo blanco.svg"
            style="width: 200px"
          /> 

          <div fxFlex="3.0rem"></div>

          <strong class="title" translate>Bienvenido a la aplicación de InklusioanBAT</strong> 

          <div fxFlex="1.0rem"></div>
        
        </mat-card-title>      

    </mat-card-content>
  </mat-card>
</div>