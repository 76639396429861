import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { AuthConfirmationAccountComponent } from './confirmation-account/confirmation-account.component';
import { AuthConfirmationComponent } from './confirmation/auth-confirmation.component';
import { AuthLoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change-dialog/password-change.component';
import { PasswordHintsComponent } from './password-hints/password-hints.component';
import { PasswordRefactorComponent } from './password-refactor/password-refactor.component';


@NgModule({
  imports: [
    AuthRoutingModule,
    SharedModule
  ],
  declarations: [
    AuthComponent,

    AuthLoginComponent,
    PasswordChangeComponent,
    PasswordRefactorComponent,
    AuthConfirmationAccountComponent,
    PasswordHintsComponent,
    AuthConfirmationComponent,
    PasswordHintsComponent
  ],
  providers: [
  ]
})
export class AuthModule { }
