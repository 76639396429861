import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-boolean-selector',
  templateUrl: './boolean-selector.component.html',
  styleUrls: ['./boolean-selector.component.scss']
})
export class BooleanSelectorComponent implements OnInit {
  @Input() label : string
  @Input() value: Boolean
  @Input() extra: string[] = [];
  @Output() selectedValueChange = new EventEmitter<Boolean>();
  control = new UntypedFormControl(null);

  constructor() { }
  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    if(this.value!=null){ this.control.patchValue(this.value)
    }else{
      this.control.patchValue(null)
    }

    this.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      this.selectedValueChange.emit(value);
    });
  }

  sfind(s: string, l:string[]): boolean{
    return l.includes(s);
  }

}

