/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { Amplify } from '@aws-amplify/core'

import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// AWS SERVICES CONFIGURATION ==================================================
Amplify.configure({
  // Authentication > Cognito Service
  Auth: environment.aws.auth,
  // API: environment.aws.api.graphql,
  // Storage: { AWSS3: environment.aws.storage.s3 }
});
