import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-password-hints',
  templateUrl: './password-hints.component.html',
  styleUrls: ['./password-hints.component.scss']
})
export class PasswordHintsComponent implements OnInit {

  private _formControl: FormControl | UntypedFormControl;

  @Input() set formControl(value: FormControl | UntypedFormControl) {
    this._formControl = value;
  }
  get formControl(): FormControl | UntypedFormControl { return this._formControl }

  constructor() { }

  ngOnInit() {
  }

}
