import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GoogleMapsModule } from '@angular/google-maps';


// External modules
import { MomentModule } from 'ngx-moment';

// Material default configurations
import { MaterialModule } from './material/material.module';
import { MATERIAL_SETTINGS } from './material/material.settings';
import { DIRECTIVES } from './directives';
import { SERVICES } from './services';
import { SNIPPETS } from './snippets';
import { PIPES } from './pipes';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { COMPONENTS_DIALOG } from './components/dialogs';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    TranslateModule,
    MomentModule,
    GoogleMapsModule,
    NgxMatIntlTelInputComponent,
    
  ],
  providers: [
    SERVICES,
    COMPONENTS_DIALOG,
    MATERIAL_SETTINGS
  ],
  declarations: [
    DIRECTIVES,
    SNIPPETS,
    PIPES,
    COMPONENTS_DIALOG
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    NgxMatIntlTelInputComponent,

    DIRECTIVES,
    SNIPPETS,
    PIPES,
    COMPONENTS_DIALOG
  ]
})
export class SharedModule { }
