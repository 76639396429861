import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { AppConstants } from '@app/@shared/app-constants';

export interface Paginator {
  length: number
  page: number;
  limit: number;
  sizeOptions: number[]
}

export interface ResultPaginated<T> {
  [x: string]: any;
  page: number,
  page_size: number,
  total: number,
  items: Array<T>
}

// REQUEST RESULT ==============================================================
export interface RequestPagination {
  pageIndex?: number;
  pageSize?: number;
}

export interface RequestOrder {
  field?: string;
  type?: SortDirection;
}

export interface RequestFilter {
  value: string;
  field: string;
}

export interface RequestQuery {
  value?: string;
  fields?: string[];
}

export interface RequestEntity {
  field?: string;
  value?: string;
}

export interface RequestContain {
  field?: string;
  values?: string[];
}

export class RequestParams {
  pagination?: RequestPagination = { pageIndex: 0, pageSize: AppConstants.DEFAULT_PAGE_SIZE, };
  order?: RequestOrder[];
  filter?: RequestFilter[];
  filter_op?: string;
  query?: RequestQuery;
  entity?: RequestEntity[];
  fields?: string[];
  contain?: RequestContain[];

  constructor(init?: Partial<RequestParams>) {
    Object.assign(this, init);
  }

  toHttpParams(): HttpParams {
    let params = new HttpParams();

    // MAPPED PAGINATION
    if (this.pagination) {
      if (this.pagination.pageSize != null) params = params.set('_page_size', this.pagination.pageSize.toString());

      if (this.pagination.pageIndex != null) params = params.set('_page', this.pagination.pageIndex.toString());
    }

    // MAPPED ORDERS
    if (this.order) {
      let _values = '';

      for (const _order of this.order) {
        let _prefix = _order.type === 'desc' ? '-' : '';
        _values = `${_values},${_prefix}${_order.field}`;
      }

      params = params.set('_order_by', _values.substring(1));
    }

    // MAPPED QUERY
    if (this.query) {
      if (this.query.value) {
        params = params.set('_filter_' + this.query.fields.join(','), '' + this.query.value);
      }
    }

    // MAPPED FILTER
    if (this.filter) {
      if (this.filter_op) params.set('_operator', this.filter_op);
      for (const _filter of this.filter) {
        if (_filter.value != null) {
          params = params.set('_filter_' + _filter.field, _filter.value);
        }
      }
    }

    // MAPPED ENTITY
    if (this.entity) {
      for (const _entity of this.entity) {
        if (_entity.value) params = params.set(_entity.field, _entity.value);
      }
    }

    if (this.fields) {
      params = params.set('_fields', this.fields.join(','));
    }

    // MAPPED CONTAIN

    if (this.contain) {
      for (const _contain of this.contain ) {
        if (_contain.field) {
          params = params.set('_filter_' + _contain.field+'__in', '' + _contain.values);
        }
      }

    }


    return params;
  }
}
