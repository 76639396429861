import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarDismiss,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { Logger } from '@app/@core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../components/dialogs/confirm-dialog/confirm-dialog.component';

export interface NotificationShowParam {
  message: string;
  action?: string;
  extraConfig?: MatSnackBarConfig;
}

const defaultConfig: MatSnackBarConfig = {
  duration: 8000,
  horizontalPosition: 'center',
  verticalPosition: 'bottom',
  // panelClass: 'success-snackbar',
};

@Injectable()
export class NotificationService {
  log = new Logger(this.constructor.name);
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  open(
    message: string,
    action: string = 'success',
    configuration: MatSnackBarConfig = new MatSnackBarConfig()
  ): Observable<MatSnackBarDismiss> {
    const capitalize = (s: string | any[]) =>
      s && s[0].toUpperCase() + s.slice(1);

    configuration.duration = configuration.duration || 6000;
    configuration.horizontalPosition =
      configuration.horizontalPosition || 'center';
    configuration.verticalPosition = configuration.verticalPosition || 'bottom';

    configuration.panelClass =
      configuration.panelClass || action
        ? action + '-snackbar'
        : 'success-snackbar';

    const _message = capitalize(this.translateService.instant(message));
    const _action = capitalize(this.translateService.instant(action));

    const snackbar = this.snackBar.open(_message, _action, configuration);

    return snackbar.afterDismissed();
  }

  /**
   * @deprecated Use `open` instead
   */
  openNotification(
    message: string,
    action: string = 'app.actions.accept',
    configuration: MatSnackBarConfig = new MatSnackBarConfig()
  ): Observable<MatSnackBarDismiss> {
    return this.open(message, action, configuration);
  }

  openConfirmDialog(data?: ConfirmDialogData): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'mat-dialog-responsive-confirm',
      data,
    });
    return dialogRef.afterClosed();
    // dialogRef.afterClosed().subscribe(() => {});
  }

  openEntityListLoadErrorNotification(
    entitiesLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entities = this.translateService.instant(entitiesLabel).toLowerCase();
    const message = this.translateService.instant(
      '_common.entityListLoadError',
      { entities }
    );
    return this.openErrorNotification(message);
  }

  openCreationSuccessNotification(
    entityLabel: string,
    actionLabel = '_common.close',
    logInfo = true
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel);
    const message = this.translateService.instant(
      '_common.entityCreationSuccess',
      { entity }
    );
    if (logInfo) {
      this.log.info(message);
    }
    return this.openSuccessNotification(message, actionLabel);
  }

  openCreationErrorNotification(
    entityLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel).toLowerCase();
    const message = this.translateService.instant(
      '_common.entityCreationError',
      { entity }
    );
    return this.openSuccessNotification(message);
  }

  openUpdateSuccessNotification(
    entityLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel);
    const message = this.translateService.instant(
      '_common.entityUpdateSuccess',
      { entity }
    );
    return this.openSuccessNotification(message);
  }

  openUpdateErrorNotification(
    entityLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel).toLowerCase();
    const message = this.translateService.instant('_common.entityUpdateError', {
      entity,
    });
    return this.openErrorNotification(message);
  }

  openDeleteSuccessNotification(
    entityLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel);
    const message = this.translateService.instant(
      '_common.entityDeleteSuccess',
      { entity }
    );
    return this.openSuccessNotification(message);
  }

  openDeleteErrorNotification(
    entityLabel: string
  ): MatSnackBarRef<TextOnlySnackBar> {
    const entity = this.translateService.instant(entityLabel).toLowerCase();
    const message = this.translateService.instant('_common.entityDeleteError', {
      entity,
    });
    return this.openErrorNotification(message);
  }

  openSuccessNotification(
    message: string,
    actionLabel = '_common.close',
    logInfo = true,
    extraConfig: MatSnackBarConfig = { panelClass: 'success-snackbar' }
  ): MatSnackBarRef<TextOnlySnackBar> {
    if (logInfo) {
      this.log.info(message);
    }
    const action =
      actionLabel != null ? this.translateService.instant(actionLabel) : null;
    return this.showNotification({ message, action, extraConfig });
  }

  openErrorNotification(
    message = this.translateService.instant('_common.couldNotPerformAction'),
    actionLabel = '_common.error',
    logInfo = true,
    extraConfig: MatSnackBarConfig = { panelClass: 'error-snackbar' }
  ): MatSnackBarRef<TextOnlySnackBar> {
    if (logInfo) {
      this.log.error(message);
    }
    const action =
      actionLabel != null ? this.translateService.instant(actionLabel) : null;
    return this.showNotification({ message, action, extraConfig });
  }

  showNotification(
    param: NotificationShowParam
  ): MatSnackBarRef<TextOnlySnackBar> {
    const message: string = param.message;
    const actionText: string = param.action;
    const config: MatSnackBarConfig = {
      ...defaultConfig,
      ...param.extraConfig,
    };
    return this.snackBar.open(
      message.toUpperCase(),
      actionText.toUpperCase(),
      config
    );
  }
}
