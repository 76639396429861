<div class="password-change-container">
  <h2 mat-dialog-title>{{ 'auth.change password.title' | translate | capitalize }}</h2>
  
  <form [formGroup]="passwordChangeForm">
    <mat-card fxLayout="column" fxLayoutGap="0.5rem">
      <mat-dialog-content>
        <div fxLayout="column">

          <!-- Contraseña actual -->
          <mat-form-field>
            <mat-label>{{ 'auth.change password.password current' | translate | capitalize }}</mat-label>
            <input matInput type="password"formControlName="password_current">
            <mat-error *ngIf="passwordChangeForm.controls.password_current.errors?.required && passwordChangeForm.controls.password_current.touched">
              <span>{{ 'Este campo es obligatorio' | translate }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Nueva contraseña -->
          <mat-form-field>
            <mat-label>{{ 'auth.change password.password replace' | translate | capitalize }}</mat-label>
            <input matInput type="password" formControlName="password_replace">
            <mat-error *ngIf="passwordChangeForm.controls.password_replace.errors?.pattern && passwordChangeForm.controls.password_replace.touched">
              <span>{{ 'app.forms.is not secure' | translate: {item: ('auth.change password.password replace' | translate | capitalize)} }}</span>
            </mat-error>
            <mat-error *ngIf="passwordChangeForm.controls.password_replace.errors?.required && passwordChangeForm.controls.password_replace.touched">
              <span>{{ 'Este campo es obligatorio' | translate }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Confirmar la nueva contraseña -->
          <mat-form-field>
            <mat-label>{{ 'auth.change password.password confirm' | translate | capitalize }}</mat-label>
            <input matInput type="password" formControlName="password_confirm">
            <mat-error *ngIf="passwordChangeForm.controls.password_confirm.errors?.mismatch && passwordChangeForm.controls.password_confirm.touched">
              <span>{{ 'app.forms.does not match' | translate: {item: ('auth.change password.password confirm' | translate | capitalize)} }}</span>
            </mat-error>
            <mat-error *ngIf="passwordChangeForm.controls.password_confirm.errors?.required && passwordChangeForm.controls.password_confirm.touched">
              <span>{{ 'Este campo es obligatorio' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <!-- <app-password-hints [formControl]="passwordChangeForm.controls?.password_replace"></app-password-hints> -->

    </mat-card>

    <mat-dialog-actions  fxLayout="row" fxLayoutGap="0.5rem">

      <button 
        fxFlex 
        mat-raised-button 
        color="inklusiobat-dialog" 
        (click)="onCancel()">
        <mat-icon>close</mat-icon>
        <span>{{ 'app.actions.cancel' | translate | capitalize }}</span>
      </button>

      <button 
        fxFlex 
        mat-raised-button 
        color="inklusiobat-dark" 
        (click)="changePassword()" 
        [disabled]="passwordChangeForm.invalid">
        <mat-icon>save</mat-icon>
        <span>{{ 'app.actions.save' | translate | capitalize }}</span>
      </button>

    </mat-dialog-actions>

  </form>
</div>
