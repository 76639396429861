<h2 mat-dialog-title>
  {{ title | translate | uppercase }}
</h2>
  <mat-dialog-content fxLayout="column" >
    <span>{{ description | translate }}</span>
  </mat-dialog-content>

<mat-dialog-actions fxLayout="row">

  <button (click)="onNoButtonClicked()" 
    mat-raised-button 
    mat-dialog-close 
    class="mat-inklusiobat-dialog"
    fxFlex>
    <mat-icon> close </mat-icon>
    {{ "app.actions.no" | translate | uppercase }}
  </button>

  <button (click)="onYesButtonClicked()" 
    mat-raised-button 
    cdkFocusInitial 
    color="primary" 
    class="mat-inklusiobat-button"
    fxFlex>
    <mat-icon> done </mat-icon>
    {{ "app.actions.yes" | translate | uppercase }}
  </button>
  
</mat-dialog-actions>