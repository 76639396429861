import { ConfirmComponent } from './confirm/confirm.component';
import { NotificationComponent } from './notification/notification.component';
import { MessageComponent } from './message/message.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

export const COMPONENTS_DIALOG = [
  ConfirmComponent,
  MessageComponent,
  NotificationComponent,
  ConfirmDialogComponent
]
