import { StepperOptions, STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { AppConstants } from '../app-constants';
// import { MatPaginatorIntl } from '@angular/material/paginator';

export const matStepperSettings: StepperOptions = {
  displayDefaultIndicatorType: false,
  showError: false
}

export const matFormFieldSettings: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  hideRequiredMarker: false,
  floatLabel: 'always'
}

export const matPaginatorSettings: MatPaginatorDefaultOptions = {
  pageSize: AppConstants.DEFAULT_PAGE_SIZE,
  pageSizeOptions: AppConstants.DEFAULT_PAGE_SIZE_OPTIONS,
  // formFieldAppearance: 'outline',
  showFirstLastButtons: true,
  hidePageSize: false
}
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 100,
  hideDelay: 100,
  touchendHideDelay: 100
};
// export function matPaginatorDefaultIntl() {
//   const _matPaginatorIntl = new MatPaginatorIntl();
//
//   _matPaginatorIntl.itemsPerPageLabel = "{{ 'test' | translate }}";
//   _matPaginatorIntl.itemsPerPageLabel = "Registros por página";
//
//   return _matPaginatorIntl;
// }


export const MATERIAL_SETTINGS = [
  { provide: STEPPER_GLOBAL_OPTIONS, useValue: matStepperSettings },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldSettings },
  { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: matPaginatorSettings },
  { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  // { provide: MatPaginatorIntl, useValue: matPaginatorDefaultIntl() }
]
