import { NgModule } from "@angular/core";
import { SharedModule } from "@app/@shared/shared.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";



@NgModule({
    imports: [
      SharedModule,
      DashboardRoutingModule
    ],
    exports: [
    ],
    declarations: [
        DashboardComponent
    ]
  })
  export class DashboardModule { }
  