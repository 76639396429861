<form [formGroup]="timeForm">
  <div fxlayout="row" fxLayoutGap="1.0rem">
    <mat-form-field fxFlex>
      <mat-label>{{ 'app.time.date' | translate | capitalize }} {{ name }}</mat-label>
      <input matInput [matDatepicker]="datePicker" placeholder="{{ 'app.time.date' | translate | capitalize }} {{ name }}" formControlName="date" />
      <!-- <mat-hint *ngIf="editable && required">{{ 'app.forms.is required' | translate: {item: ('app.time.date' | translate | titlecase )} }} {{ name }}</mat-hint> -->
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>

      <mat-error *ngIf="timeForm.controls.date.errors?.required || timeForm.controls.date.touched">
        <span>{{ 'app.forms.is required' | translate: {item: ('app.time.date' | translate | titlecase )} }} {{ name }}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'app.time.time' | translate | capitalize }} {{ name }}</mat-label>
      <input matInput type="time" placeholder="{{ 'app.time.time' | translate | capitalize }} {{ name }}" formControlName="time"/>
      <!-- <mat-hint *ngIf="editable && required">{{ 'app.forms.is required' | translate: {item: ('app.time.time' | translate | titlecase )} }} {{ name }}</mat-hint> -->
      <mat-error *ngIf="timeForm.controls.time.errors?.required || timeForm.controls.time.touched">
        <span>{{ 'app.forms.is required' | translate: {item: ('app.time.time' | translate | titlecase )} }} {{ name }}</span>
      </mat-error>
    </mat-form-field>
  </div>
</form>
