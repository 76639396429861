import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { RequestParams, ResultPaginated } from "../models/_results"

@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    public readonly users = 'user'
    public readonly adsl_type = 'resources/adsl_type'
    public readonly failure_categories = 'categories/fails'
    public readonly catalog_function = 'catalog/functions'
    
  
    constructor(private http: HttpClient) { }
    //USE FOR GENERAL-SELECTOR
    find(param: RequestParams, entity: string): Observable<ResultPaginated<any>> {
      const url = `/${entity}`;
      const params = param.toHttpParams();
  
      return this.http.get<ResultPaginated<any>>(url, { params });
    }
}

