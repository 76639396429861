import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'smartDateFormat',
})
export class SmartDateFormatPipe implements PipeTransform {

  transform(value: string, format?:string , ...args: unknown[]): string {

    const today = moment().startOf('day');
    const valueAsMoment = moment(value);
    const isToday = moment(value).startOf('day').isSame(today);

    let result: string;
    if (!format && isToday) {
      result = moment(value).fromNow();
    } else {
      // TODO: Check time is local or UTC+0
      result = valueAsMoment.local().format(format ? format : 'DD/MM/YY HH:mm');
    }
    return value ? result : '';
  }
}
