import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'capitalize', pure: false })
export class CapitalizePipe implements PipeTransform {
  constructor() {}
  transform(text: string): string {
    const capitalizeText = (s: string | any[]) => s && s[0].toUpperCase() + s.slice(1)
    return capitalizeText(text);
  }
}
