import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { AuthSessionGuard } from './auth.guard';

import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/login.component';
import { AuthConfirmationComponent } from './confirmation/auth-confirmation.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent, children: [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: AuthLoginComponent, data: { title: marker('auth.login.title') }},
    { path: 'confirmation', component: AuthConfirmationComponent, data: { title: marker('auth.confirmation.title') }},
  ], canActivate: [AuthSessionGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule { }
