<div class="auth-confimation-container" fxLayout="column">
  <mat-card>
    <ng-container *ngIf="confirmationErrorMsg; else noErrorTemplate">
      <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="center center">

        <h1>
          {{ 'auth.confirmation.confirmation error' | translate }}
        </h1>

        <span>{{ confirmationErrorMsg | translate }}</span>
      </div>
    </ng-container>

    <ng-template #noErrorTemplate>
      <div fxLayout="column" fxLayoutGap="1.0rem" fxLayoutAlign="center center">

        <h1>
          {{ 'auth.confirmation.successful confirmation heading' | translate }}
        </h1>

        <h2>
          {{ 'auth.confirmation.successful confirmation subheading' | translate }}
        </h2>

      </div>
    </ng-template>

    <br>

    <button mat-flat-button color="primary" routerLink='/auth/login'>
      <span>{{ 'auth.confirmation.go login' | translate }}</span>
    </button>
  </mat-card>
</div>
